:root {
  --disabled: #d8dbde;
}

#root {
  height: 100%;
  overflow: hidden;
}

#example-map {
  height: 100%;
  width: 100%;
}

#page-header .primary-nav > ul > li.active.clickable > a {
  transition: border 100ms ease-in, box-shadow 100ms ease-in;
  border-radius: 20px;
  cursor: pointer;
}

#page-header .primary-nav > ul > li.active.clickable:hover > a {
  border: 2px solid rgba(0, 0, 0, 0.34);
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.45);
}

#page-header .primary-nav ul li span {
  cursor: pointer;
}

@keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border .75s linear infinite;
  animation: spinner-border .75s linear infinite;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@-webkit-keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    opacity: 1;
  }
}

@keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    opacity: 1;
  }
}

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  -webkit-animation: spinner-grow .75s linear infinite;
  animation: spinner-grow .75s linear infinite;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

.was-validated .form-control:invalid, .form-control.is-invalid, .was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
  box-shadow: 0px 0px 0px 2px rgba(220, 59, 26, 0.5), inset 1px 0 5px rgba(0, 0, 0, 0.03);
}

.modal .close span {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  outline: none;
}

.modal .close {
  transition: opacity 300ms ease-in;
}

.modal .close:active, .modal .close:focus {
  outline: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
  -moz-appearance: textfield; /* Firefox */
}

.form-control[readonly], .form-control:disabled {
  color: #939393;
}

.parameters-form input.form-control, .parameters-form.form-group {
  margin-bottom: 0;
}

.modal .alert-dismissible .close {
  border-radius: 0;
  margin: 0;
  font-family: inherit;
  overflow: visible;
  text-transform: none;
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  text-shadow: 0 1px 0 #fff;
  opacity: .5;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
  cursor: pointer;
  position: absolute;
  top: 6px;
  right: 0;
  color: inherit;
  box-shadow: none;
  /* color: inherit; */
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5;
}

.input-group > .input-group-prepend > .input-group-text {
  cursor: auto;
  box-shadow: 0px 0px 0px 2px rgba(0, 0, 0, 0.1), inset 1px 0 5px rgba(0, 0, 0, 0.03);
  border-radius: 0;
  transition: 0.3s;
  border: none;
  flex-grow: 1;
}

.input-group > .input-group-prepend {
  flex-grow: 1;
  flex-basis: 20px;
}

.input-group > .form-control:not(:first-child)::-webkit-outer-spin-button,
.input-group > .form-control:not(:first-child)::-webkit-inner-spin-button {
  -webkit-appearance: inner-spin-button;
  margin: -3px;
}

.input-group > .form-control, .input-group > .custom-select, .input-group > .custom-file {
  position: relative;
  flex: 1 0 20px;
  margin-bottom: 0;
}

.pac-container.pac-logo {
  z-index: 10000;
}

input[type="text"].disabled {
  background-color: var(--disabled);
}

input[type="text"].disabled:hover{
  box-shadow:  0px 0px 0px 2px rgba(0, 0, 0, 0.1), inset 1px 0 5px rgba(0, 0, 0, 0.03);
}

.map .cluster > div{
  width: 36px !important;
  left: 24% !important;
}

.page-item:last-child .page-link {
  border-top-right-radius: 100%;
  border-bottom-right-radius: 100%;
}
.page-item:first-child .page-link {
  border-top-left-radius: 100%;
  border-bottom-left-radius: 100%;
}
.page-item.active .page-link{
  border-radius: 100%;
  color: #fff;
  background-color: var(--primary);
  border-color: #aba7be;
}